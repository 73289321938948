import  firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'

//import { cityDb } from './temp/m-city-export';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBeyZaqQ1w-Q8Np1o20-uHnXwDruMls4h4",
	authDomain: "football-8980a.firebaseapp.com",
	projectId: "football-8980a",
	storageBucket: "football-8980a.appspot.com",
	messagingSenderId: "427533880717",
	appId: "1:427533880717:web:a3311b74999bcfed0981c4",
	measurementId: "G-5E1WFPPLFS"
};

 // Initialize Firebase
firebase.initializeApp(firebaseConfig);
const DB = firebase.firestore();
const matchesCollection = DB.collection('matches');
const playersCollection = DB.collection('players');
const positionsCollection = DB.collection('positions').orderBy('pos');
const promotionsCollection = DB.collection('promotions');
const teamsCollection = DB.collection('teams');

 //cityDb.matches.forEach(item => {
 //  matchesCollection.add(item)
 //});


 //cityDb.players.forEach(item => {
 //  playersCollection.add(item)
 //});

//cityDb.positions.forEach(item => {
 //  positionsCollection.add(item)
 //});

//cityDb.promotions.forEach(item => {
 //  promotionsCollection.add(item)
 //});

//cityDb.teams.forEach(item => {
 // teamsCollection.add(item)
 //});



export {
    firebase,
    matchesCollection,
    playersCollection,
    positionsCollection,
    promotionsCollection,
    teamsCollection
}