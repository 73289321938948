import React from 'react';

const NotFound = () => {
    return(
        <div className="not_found_container">
            <div>Sorry :(</div>
            <div>
                Page not found
            </div>
        </div>
    )
}

export default NotFound;