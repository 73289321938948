import React from 'react';
import AdminLayout from '../HOC/AdminLayout'

const Dashboard = (props) => {

	return(
		<AdminLayout>
			<div className="user_dashboard">
				This is your dashboard
			</div>
		</AdminLayout>
	)
}

export default Dashboard