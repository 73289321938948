import React from 'react';
import { easePolyOut } from 'd3-ease'
import Animate from 'react-move/Animate'


import Grealish from '../../../Resources/images/players/grealish.png';
import Sterling from '../../../Resources/images/players/Raheem_Sterling.png';
import Steffen from '../../../Resources/images/players/steffen.png';
import PlayerCard from '../../Utils/playerCard';


let cards = [
    {
        bottom:90,
        left:300,
        player:Grealish
    },
    {
        bottom:60,
        left:200,
        player:Sterling
    },
    {
        bottom:30,
        left:100,
        player:Steffen
    },
    {
        bottom:0,
        left:0,
        player:Grealish
    }
]

const HomeCards = (props) => {

    const showAnimateCards = () => (
        cards.map((card,i)=>(
            <Animate
                key={i}
                show={props.show}
                start={{
                    left:0,
                    bottom:0
                }}
                enter={{
                    left:[card.left],
                    bottom:[card.bottom],
                    timing:{ delay:500,duration: 500, ease:easePolyOut}
                }}
            >
                {({left,bottom})=>(
                    <div
                        style={{
                            position:'absolute',
                            left,
                            bottom
                        }}
                    >
                       <PlayerCard
                            number="10"
                            name="Jack"
                            lastname="Grealish"
                            bck={card.player}
                       />
                    </div>
                )}
            </Animate>
        ))
    )


    return(
        <div>
            {showAnimateCards()}
        </div>
    )
}

export default HomeCards;