import React from 'react';
//import Test from './test';
import Stripes from './Stripes';
import Text from './Text'

const Featured = () => {
	return(
		<div className="featured_wrapper">
			<Stripes/>
			<Text/>
		</div>
	)
}

export default Featured